import { Button, Layout, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { AppContext } from '../AppContext';
import { BREAKPOINTS, ROUTES } from '../common/constants';
import RouterPrompt from '../components/RouterPrompt';
import AppHeader from './components/header/AppHeader';
import UserProfile from './components/header/UserProfile';
import { LogoSvg, TerramaticLogo } from '../assets/svg';
import ContentRoutes from './ContentRoutes';
import './app.less';
import Sidebar from './components/sidebar/Sidebar';
import MobileLoginSignup from '../components/MobileLoginSignup';

const { Content, Sider } = Layout;

const App = () => {
  const { state } = useContext(AppContext);
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('');
  const collapsedPaths = [
    'synchronization',
    'add-detection',
    'detection-detail',
    'consolidated-view',
    'reports',
    'traffic-analysis',
    'accident-analysis',
    'see-all',
  ];

  useEffect(() => {
    const handleOffline = () => {
      message.error('No Internet. Please try again after some time!');
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('offline', handleOffline);

    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('offline', handleOffline);
    };
    // eslint-disable-next-line no-undef
  }, [navigator?.onLine]);

  useEffect(() => {
    const pathParts = location?.pathname?.split('/');
    const foundCollapsedPath = collapsedPaths.some((path) =>
      pathParts.includes(path),
    );

    setCollapsed(foundCollapsedPath);
  }, [location.pathname]);

  useEffect(() => {
    const pathParts = location?.pathname?.split('/');

    if (pathParts.length > 1) {
      const firstPart = pathParts[1];
      if (firstPart === 'personal' || firstPart === 'agency') {
        setHeaderTitle('Settings');
      } else {
        const capitalizedFirstLetter = firstPart?.charAt(0)?.toUpperCase();
        const restOfWord = firstPart?.slice(1);
        setHeaderTitle(capitalizedFirstLetter + restOfWord);
      }
    }
  }, [location.pathname]);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <>
      {isDesktop ? (
        <Layout hasSider className="main-layout">
          <RouterPrompt openPrompt={state?.showPrompt} />
          {!isDesktop && (
            <span
              className={
                isActive ? 'active overlay-responsive' : 'overlay-disable'
              }
              onClick={handleOverlay}
            />
          )}
          <Sider
            collapsible
            collapsed={collapsed}
            theme="light"
            width="260px"
            className={isActive ? 'close' : null}
            breakpoint="md"
            onCollapse={() => setCollapsed(!collapsed)}
          >
            <div className="terramatic-logo">
              <Link to={ROUTES?.MAIN}>
                {collapsed ? <LogoSvg /> : <TerramaticLogo />}
              </Link>
            </div>

            <Sidebar />
          </Sider>
          <Layout className="site-layout">
            <AppHeader>
              <div>
                {isDesktop ? (
                  <div>{headerTitle}</div>
                ) : (
                  <Button
                    className="trigger"
                    type="text"
                    onClick={handleOverlay}
                    icon={<MenuOutlined />}
                    size="middle"
                  />
                )}
              </div>
              <div>
                <UserProfile />
              </div>
            </AppHeader>
            <Content className="wrapper" id="wrapper-to-scroll">
              <ContentRoutes />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <MobileLoginSignup signup />
      )}
    </>
  );
};

export default App;
